export function autoresizeTextarea(element) {
  element.setAttribute("style", "height:" + element.scrollHeight + "px;overflow-y:hidden;");
  element.addEventListener("input", resize, false);

  // const tx = document.getElementsByTagName("textarea");
  // for (let i = 0; i < tx.length; i++) {
  //     tx[i].setAttribute("style", "height:" + (tx[i].scrollHeight) + "px;overflow-y:hidden;");
  //     tx[i].addEventListener("input", OnInput, false);
  // }

  function resize() {
    element.style.height = 0;
    element.style.height = element.scrollHeight + "px";
  }
  function delayedResize() {
    window.setTimeout(resize, 0);
  }
  ['change', 'cut', 'paste', 'drop', 'keydown'].forEach(function (e) {
    element.addEventListener(e, delayedResize, false);
  });
}
export function autoresizeTextareaInit() {
  var containerSelector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'body';
  var container = document.querySelector(containerSelector);
  var autoresizeTextareaElements = container.querySelectorAll(".autoresize-textarea");
  for (var i = 0; i < autoresizeTextareaElements.length; i++) {
    autoresizeTextarea(autoresizeTextareaElements[i]);
  }
}