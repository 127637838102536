export function lightboxInit() {
  var lightboxElements = document.querySelectorAll('.open-video-lightbox');
  for (var i = 0; i < lightboxElements.length; i++) {
    lightboxElements[i].addEventListener('click', function (e) {
      e.preventDefault();
      var link = e.target.href;
      if (link) {
        basicLightbox.create("<iframe src=\"".concat(link, "\" width=\"1260\" height=\"710\" title=\"Citadel Law Video\" frameBorder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture\" allowfullscreen></iframe>")).show();
      }
    });
  }
}