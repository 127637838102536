function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import { publicConfig } from "../publicConfig";
import { handleFormData } from "./form";
import { httpRequest } from "./httpRequest";
import { getAdsTracking } from "./ads";
import { loadingButton } from "./utils/loadingButton";
export function gMapsTabsInit() {
  var triggerTabList = document.querySelectorAll('.address-box button');
  triggerTabList.forEach(function (triggerEl) {
    var tabTrigger = new bootstrap.Tab(triggerEl);
    triggerEl.addEventListener('click', function (event) {
      event.preventDefault();
      var addressBoxes = document.querySelectorAll('.addresses-wrapper .address-box');
      addressBoxes.forEach(function (el) {
        el.classList.remove('active');
      });
      event.target.closest('.address-box').classList.add('active');
      tabTrigger.show();
    });
  });
}
export function contactFormInit() {
  document.addEventListener("click", function (e) {
    if (!e.target.matches(".send-message")) return;
    e.preventDefault();
    var btn = e.target;
    var formId = btn.dataset.form;
    if (!formId) return console.warn(publicConfig.errors.formId);
    var form = document.getElementById(formId);
    if (!form) return console.warn(publicConfig.errors.form);
    var route = form.action;
    if (!route) return console.warn(publicConfig.errors.route);
    var formDataArray = handleFormData(form);
    var currentURL = window.location.href;
    if (currentURL) {
      formDataArray.push(['url', currentURL]);
    }
    var adsTracking = getAdsTracking();
    if (adsTracking) {
      formDataArray.push(['adsType', adsTracking.type]);
      formDataArray.push(['adsClickId', adsTracking.id]);
    }
    var eventName = form.dataset.ev || 'ServicesPagesContactForm';
    var queryString = new URLSearchParams(formDataArray).toString();
    var formMessage = form.querySelector('.form-message');
    // console.log(form.checkValidity());
    if (!form.checkValidity()) {
      // console.log("test checkValidity");
      form.classList.add('was-validated');
      return false;
    } else {
      form.classList.remove('was-validated');
      loadingButton.start(btn);
      // citadel_app.button.loading.start(btn)
      httpRequest({
        url: route,
        data: queryString
      }).then(function (response) {
        if (response.data.redirect) {
          return window.location.href = response.data.redirect;
        }
        formMessage.style.display = 'block';
        formMessage.classList.remove('fail', 'success');
        console.info(response.data.error);
        console.info(response.data.success);
        if (response.data.error) {
          formMessage.classList.add('fail');
          formMessage.textContent = response.data.error;
        }
        if (response.data.success) {
          formMessage.classList.add('success');
          formMessage.textContent = response.data.success;
          form.reset();
          if (adsTracking) {
            if (adsTracking.type === 'gclid') {
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                'event': eventName
              });
            }
            if (adsTracking.type === 'msclkid') {
              window.uetq = window.uetq || [];
              window.uetq.push('event', eventName, {});
            }
            // if(adsTracking.type == 'fbclid') {
            //     fbq('track', 'Lead');
            // }
            // if(adsTracking.type == 'adroll') {
            //
            // }
          }
          //
          // window.mtagq = window.mtagq || [];
          // window.mtagq.push("event", "submit_lead_form", {"revenue_value": 0, "currency": "USD"});
        }

        setTimeout(function () {
          formMessage.style.display = 'none';
        }, 30000);
      })["finally"](function () {
        loadingButton.finish(btn);

        // citadel_app.button.loading.finish(btn)
      });
    }
  });
}

export function gMapInit() {
  // https://stackoverflow.com/questions/27662311/add-view-on-google-maps-custom-button-on-google-map-programmatically
  if ((typeof google === "undefined" ? "undefined" : _typeof(google)) === 'object') {
    var initMap = function initMap() {
      for (var i = 0, length = coords.length; i < length; i++) {
        var point = coords[i];
        var latlng = new google.maps.LatLng(point.lat, point.lng);
        maps[i] = new google.maps.Map(document.getElementsByClassName('g-map')[i], {
          zoom: point.zoom,
          center: latlng,
          streetViewControl: false,
          styles: [{
            "featureType": "all",
            "elementType": "labels.text",
            "stylers": [{
              "color": "#878787"
            }, {
              "visibility": "on"
            }]
          }, {
            "featureType": "all",
            "elementType": "labels.text.stroke",
            "stylers": [{
              "visibility": "off"
            }]
          }, {
            "featureType": "landscape",
            "elementType": "all",
            "stylers": [{
              "visibility": "on"
            }, {
              "lightness": "0"
            }]
          }, {
            "featureType": "poi",
            "elementType": "labels",
            "stylers": [{
              "visibility": "off"
            }]
          }, {
            "featureType": "road",
            "elementType": "labels.icon",
            "stylers": [{
              "visibility": "simplified"
            }]
          }, {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [{
              "color": "#c9c9c9"
            }]
          }, {
            "featureType": "transit.station",
            "elementType": "labels",
            "stylers": [{
              "visibility": "off"
            }]
          }, {
            "featureType": "water",
            "elementType": "all",
            "stylers": [{
              "color": "#aee0f4"
            }]
          }]
        });
        for (var j = 0; j < coords[i].marks.length; j++) {
          if (!markers[i]) markers[i] = [];
          if (!infoWindow[i]) infoWindow[i] = new google.maps.InfoWindow();
          markers[i][j] = new google.maps.Marker({
            position: {
              lat: coords[i].marks[j].mlat,
              lng: coords[i].marks[j].mlng
            },
            map: maps[i]
          });
          google.maps.event.addListener(markers[i][j], 'click', function (map, content, infoWindow) {
            return function (e) {
              infoWindow.setContent(content);
              infoWindow.open(map, this);
            };
          }(maps[i], coords[i].marks[j].mCont, infoWindow[i]));
        }
      }
    };
    var irvineLat = 33.68165275514819;
    var irvineLng = -117.84916548466137;
    var beverlyHillsLat = 34.06929099999887;
    var beverlyHillsLng = -118.40513393005861;
    var sanDiegoLat = 32.94535744252576;
    var sanDiegoLng = -117.24175346956298;
    var sanFranciscoLat = 37.790466842140454;
    var sanFranciscoLng = -122.40112310997425;
    var coords = [{
      lat: irvineLat,
      lng: irvineLng,
      zoom: 17,
      marks: [{
        mlat: irvineLat,
        mlng: irvineLng,
        mCont: "<div class='fw-bold h4 mb-1'>Irvine</div><div>2372 Morse Ave, Irvine, CA 92614</div>"
      }]
    }, {
      lat: beverlyHillsLat,
      lng: beverlyHillsLng,
      zoom: 17,
      marks: [{
        mlat: beverlyHillsLat,
        mlng: beverlyHillsLng,
        mCont: "<div class='fw-bold h4 mb-1'>Beverly Hills</div><div>9777 Wilshire Blvd. #400, Beverly Hills, CA 90210</div>"
      }]
    }, {
      lat: sanDiegoLat,
      lng: sanDiegoLng,
      zoom: 17,
      marks: [{
        mlat: sanDiegoLat,
        mlng: sanDiegoLng,
        mCont: "<div class='fw-bold h4 mb-1'>San Diego</div><div>12526 High Bluff Dr #300, San Diego, CA 92130</div>"
      }]
    }, {
      lat: sanFranciscoLat,
      lng: sanFranciscoLng,
      zoom: 17,
      marks: [{
        mlat: sanFranciscoLat,
        mlng: sanFranciscoLng,
        mCont: "<div class='fw-bold h4 mb-1'>San Francisco</div><div>1 Sansome St #3500, San Francisco, CA 94104</div>"
      }]
    }];
    var markers = [];
    var maps = [];
    var infoWindow = [];
    initMap();
  }
}