export function initOffcanvasForm() {
  var offcanvasSchedule = document.getElementById('offcanvas-schedule');
  if (offcanvasSchedule) {
    var navbarOffcanvasBackdropCreate = function navbarOffcanvasBackdropCreate() {
      var backdrop = document.createElement("div");
      backdrop.classList.add('offcanvas-backdrop', 'fade', 'show');
      var navbar = document.getElementById("navbar-nav");
      navbar.parentNode.insertBefore(backdrop, navbar.nextSibling);
      backdrop.addEventListener('click', function (event) {
        closeNavbarOffcanvas();
      });
    };
    var navbarOffcanvasBackdropRemove = function navbarOffcanvasBackdropRemove() {
      document.querySelector('.offcanvas-backdrop').remove();
    }; // darken_screen
    // function darken_screen(yesno){
    //     if( yesno == true ){
    //         offcanvasBackdropCreate();
    //         // document.querySelector('.screen-darken').classList.add('active');
    //     }
    //     else if(yesno == false){
    //         offcanvasBackdropRemove();
    //         // document.querySelector('.screen-darken').classList.remove('active');
    //     }
    // }
    var closeNavbarOffcanvas = function closeNavbarOffcanvas() {
      navbarOffcanvasBackdropRemove();
      document.querySelector('.navbar-offcanvas.show').classList.remove('show');
      // document.body.style.cssText = '';
      document.body.classList.remove('navbar-offcanvas-active');
    };
    var showNavbarOffcanvas = function showNavbarOffcanvas(offcanvasId) {
      navbarOffcanvasBackdropCreate();
      document.getElementById(offcanvasId).classList.add('show');
      // document.body.style.cssText = 'overflow:hidden';
      document.body.classList.add('navbar-offcanvas-active');
    };
    offcanvasSchedule.addEventListener('shown.bs.offcanvas', function (event) {
      var firstInput = offcanvasSchedule.querySelector('input');
      // console.info(firstInput)
      firstInput.focus();
    });
    document.querySelectorAll('[data-navbar-offcanvas]').forEach(function (element) {
      var offcanvasId = element.getAttribute('data-navbar-offcanvas');
      element.addEventListener('click', function (e) {
        e.preventDefault();
        showNavbarOffcanvas(offcanvasId);
      });
    });
    document.querySelectorAll('.navbar-offcanvas-close').forEach(function (element) {
      element.addEventListener('click', function (e) {
        closeNavbarOffcanvas();
      });
    });
  }
}
export function initScrollNavbar() {
  var prevScrollPos = window.pageYOffset;
  window.addEventListener('scroll', function () {
    // console.info('rrrr')
    // console.info('prevScrollpos', prevScrollPos)
    var currentScrollPos = window.pageYOffset;
    // console.info('currentScrollPos', currentScrollPos)
    // if (prevScrollPos > currentScrollPos) {
    //     document.getElementById("header").classList.add('bg-white', 'is-sticky');
    //     document.getElementById("header").style.top = "0";
    // } else {
    //     document.getElementById("header").classList.remove('bg-white', 'is-sticky');
    //     // document.getElementById("header").style.top = "-142px"; /* width horizontal navbar */
    // }

    if (currentScrollPos > 100) {
      var _document$getElementB;
      // document.getElementById("header").style.top = "0";
      (_document$getElementB = document.getElementById("header")) === null || _document$getElementB === void 0 ? void 0 : _document$getElementB.classList.add('bg-white', 'is-sticky');
    }
    if (currentScrollPos === 0) {
      var _document$getElementB2;
      (_document$getElementB2 = document.getElementById("header")) === null || _document$getElementB2 === void 0 ? void 0 : _document$getElementB2.classList.remove('bg-white', 'is-sticky');
    }
    prevScrollPos = currentScrollPos;
  });

  // document.querySelector('#navbarSideCollapse').addEventListener('click', () => {
  //     document.querySelector('.offcanvas-collapse').classList.toggle('open')
  // })
}

export function initStickyHeader() {
  var headerOffset = 80;
  var currentScrollPos = window.pageYOffset;
  if (headerOffset < currentScrollPos) {
    var _document$getElementB3;
    (_document$getElementB3 = document.getElementById("header")) === null || _document$getElementB3 === void 0 ? void 0 : _document$getElementB3.classList.add('bg-white', 'is-sticky');
  }
}