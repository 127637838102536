//?fbclid=IwAR0K372fdAcDcgosAgrL8nTxL2jvrZ-lRdCUehvy1LPHOk9BkfuC7xRmPW8_aem_Ae-qRBogo4ehJvr8E2v4Xx9aWF0nisIhR_RLOTW4h5uHYAD8P0riEVREl5Lyik_kgnSWgsCO0S84wlOGhP7MfZEpX8epqc8Xs21_ix2QSiyPk-aG7L3LY8axq75BcsH60C4].
//?msclkid=2c194ac19d0311f9a85e2ee0d9032b31].
//?gclid=EAIaIQobChMI8rnMy9H7_QIVhdz9BR3rkA4IEAEYASAAEgJV-fD_BwE].
//?utm_source=adroll&utm_medium=adroll&utm_campaign=adroll].
//?fp=dUj5HI6LZk%2BOGw08a1HzTOOSiaURtygZEmc1BRiVzzIvS7qfqXZxysz%2BLRWdNHnUl3YOOUgj19QmjKQsvls9Scp7DVHE6fS5H5REr/G2zOHWvDMdkWswOl%2BzWgKWXqgpPPpa3yyu8QS4Z9rcRwvz2%2B/n0LLWoDEy4FMsC2qY4QY%3D&prvtof=TnrUqnJl%2BreAH0sM1IHnblzYvde7X5XJqadOLQmL%2Bd8%3D&poru=7Yf19hKs%2B/Ueb47N33K/ornLVnqL01yy9WnyEMx6BCnX3PsLFf%2B8PKACKr21O7NhWkScyAyaBIziJpKPmHy46Q%3D%3D].

function getParam(p) {
  var match = RegExp('[?&]' + p + '=([^&]*)').exec(window.location.search);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}
function getExpiryRecord(type, id) {
  var expiryPeriod = 90 * 24 * 60 * 60 * 1000; // 90 day expiry in milliseconds

  var expiryDate = new Date().getTime() + expiryPeriod;
  return {
    type: type,
    id: id,
    expiryDate: expiryDate
  };
}
function addGclid() {
  var gclidParam = getParam('gclid');
  var gclidFormFields = ['gclid_field', 'foobar']; // all possible gclid form field ids here
  var gclidRecord = null;
  var currGclidFormField;
  var gclsrcParam = getParam('gclsrc');
  var isGclsrcValid = !gclsrcParam || gclsrcParam.indexOf('aw') !== -1;
  gclidFormFields.forEach(function (field) {
    if (document.getElementById(field)) {
      currGclidFormField = document.getElementById(field);
    }
  });
  if (gclidParam && isGclsrcValid) {
    gclidRecord = getExpiryRecord(gclidParam);
    localStorage.setItem('gclid', JSON.stringify(gclidRecord));
  }
  var gclid = gclidRecord || JSON.parse(localStorage.getItem('gclid'));
  var isGclidValid = gclid && new Date().getTime() < gclid.expiryDate;
  if (currGclidFormField && isGclidValid) {
    currGclidFormField.value = gclid.value;
  }
}
function setAds() {
  var currentAdsClickId,
    currentAdsType,
    clickIdRecord = null,
    clickProviders = ['gclid', 'msclkid', 'fbclid', 'utm_source'];
  clickProviders.every(function (adsType) {
    currentAdsClickId = getParam(adsType);
    if (currentAdsClickId) {
      currentAdsType = adsType;
      return false;
    }
    return true;
  });

  // console.info('currentAdsType', currentAdsType)
  // console.info('currentAdsClickId', currentAdsClickId)
  // console.info('getParam', getParam('msclkid'))

  if (currentAdsClickId) {
    if (currentAdsType === 'utm_source') {
      currentAdsType = 'adroll';
    }
    clickIdRecord = getExpiryRecord(currentAdsType, currentAdsClickId);
    localStorage.setItem('citadel_clid', JSON.stringify(clickIdRecord));
  }
}
export function getAdsTracking() {
  var currentClickId = JSON.parse(localStorage.getItem("citadel_clid"));
  return currentClickId !== null && currentClickId !== void 0 ? currentClickId : null;
}
export function adsTrackingInit() {
  window.addEventListener('load', setAds);
}